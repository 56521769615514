<template>
    <div class="ml-5 mt-5">
    <div>
        <h2>{{title}}</h2>
        <el-button icon="el-icon-plus" circle @click="addcount"></el-button>
        <el-button icon="el-icon-minus" circle @click="subcount" v-if="count >=2"></el-button>
    </div>
    <div class="row mx-md-n5">
        <AddComponent v-for="i in count" :key="i" />
    </div>
        
    </div>
</template>

<script>

    import AddComponent from '@/components/dashboard/services/AddServComponent.vue'

    export default {

        components: {
            AddComponent
        },

        data() {
            return {
                // lang
                title: this.$t('pages.services.services_add.title'),
                count: 1,
            }
        },

        methods: {
            addcount(){
                this.count++
            },
            subcount(){
                if (this.count >= 2) {
                    this.count--
                }
            },
        }
    }
</script>